export const PreventiveCareSchema = () => {

  let data = {
    "@context": "https://schema.org", 
    "@type": "Service", 
    "serviceType": "Preventive Care", 
    "provider": { 
      "@type": "Organization", 
      "name": "My Family MD", 
      "url": "https://myfamilymd.org/", 
      "telephone": "(256)-489-9741" 
    }, 
    "areaServed": { 
      "@type": "City", 
      "name": "Huntsville" 
    }, 
    "description": "Comprehensive preventive care services aimed at minimizing disease burden and promoting overall wellness. Our services include screenings, vaccinations, lifestyle guidance, and early detection of health issues.", 
    "url": "https://myfamilymd.org/preventive-care-clinic-huntsville", 
    "image": "https://myfamilymd.org/og.png"   
  } 

  return JSON.stringify(data);
};