import React, {useEffect} from "react"
import { Helmet } from "react-helmet";
import {GeoAltFill, TelephoneFill} from "react-bootstrap-icons"
import { HashLink as Link } from "react-router-hash-link"
import HeaderLogo from '../assets/images/home/HeaderLogo.png'
import Appointment from '../assets/images/landingpage/appointment.webp'
import MedicalCheckup from '../assets/images/landingpage/medical-checkup.webp'
import MedicalTeam from '../assets/images/landingpage/medical-team.webp'
import Icon1 from '../assets/images/landingpage/icon1.webp'
import Icon2 from '../assets/images/landingpage/icon2.webp'
import Icon3 from '../assets/images/landingpage/icon3.webp'
import Icon4 from '../assets/images/landingpage/icon4.webp'
import Doctor1 from '../assets/images/landingpage/1.webp'
import Doctor2 from '../assets/images/landingpage/2.webp'
import Doctor3 from '../assets/images/landingpage/3.webp'




const LandingPage=()=> {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
        <Helmet>
        <title>Family Healthcare Clinic in Huntsville - My Family MD</title>
        </Helmet>
        <section className="position-sticky-landing-page">
        <section className="container-fluid pt-3 font-pri-hsb py-2 bg-white">
            <div className="row justify-content-center align-items-center gy-2">
                <div className="col-12 col-md-4 text-capitalize text-center text-md-start font-16-landing-page fw-700 text-pri-hsb">
                    <div>
                        We are accepting new patients
                    </div>
                </div>
                <div className="col-12 col-md-3 text-center">
                    <div>
                    <Link to="/">
                    <img className="w-logo-landing-page" src={HeaderLogo} alt="Header Logo" />
                    </Link>
                    </div>
                </div>
                <div className="col-12 col-md-4 col-xl-4 text-md-end">
                    <div>
                    <div className="">
                    <a href="tel:+12564899741" className="td-none text-pri-hsb"  target="_blank" > 
                    <TelephoneFill className="d-inline d-md-none text-sec-hsb"/>
                    <span className="font-16-landing-page-head fw-600 fst-normal px-3">(256)-489-9741 </span>
                    <TelephoneFill className="d-none d-md-inline text-sec-hsb"/>
                    </a>
                    </div>
                    
                    <div className="">
                    <a href="https://www.google.com/maps/place/Iqbal+Saeed+MD+LLC/@34.699686,-86.6030182,18z/data=!4m6!3m5!1s0x88626cea37639e9b:0x5c30f864a919d024!8m2!3d34.6994209!4d-86.6028594!16s%2Fg%2F1tfbjg5q" className="td-none text-pri-hsb" target="_blank"> 
                    <GeoAltFill className="d-inline d-md-none text-sec-hsb"/>
                    <span className="font-16-landing-page-head fw-600 fst-normal px-3"> 2227 Drake Ave. Suite 7A Huntsville, AL 35805</span>
                    <GeoAltFill className="d-none d-md-inline text-sec-hsb" />
                    </a>
                    </div>
                    </div>
                </div>
            </div>
        </section>
        </section>

        <section className="container-fluid px-0 font-pri-hsb">
            <div className="row gx-0 landing-page-banner px-0">
                <div className="py-md-2 my-md-4 px-3">
                <div className="title-landing-page py-5 my-md-5 text-center text-white">
                    <div className="fs-ss-46 text-capitalize fw-700 lh-sm pt-lg-5">
                    Looking for a trusted primary care clinic?
                    </div>
                    {/* <div className="fs-ss-35-banner py-2">
                    From Preventive Care to Screenings. <div className="fw-600 pt-4"> Schedule Your Visit Now! </div> 
                    </div> */}

                    <div className="fs-ss-35-banner pt-4 pb-2">
                    Schedule Your Visit Now!
                    </div>
                    <div className="pt-2 mb-lg-5">
                    {/* <button className="btn px-5 py-1 rounded-5 border-1 border-white border">
                        <a href="tel:+12564899741" className="td-none text-white"  target="_blank" > 
                    <span className="fs-ss-22 fw-500 fst-normal">(256)-489-9741 </span>
                    </a>
                        </button> */}
                        <button className="px-md-5 px-4 py-1 py-md-2 rounded-5 bg-btn-primary hover-pri fw-600 shadow fs-ss-22">
                        <a href="tel:+12564899741" className="td-none text-white"  target="_blank" > 
                        (256)-489-9741
                        </a>
                        </button>
                    </div>
                </div>  
                </div>
            </div>          
        </section>

        <section className="container-fluid pt-4 pt-md-5 font-pri-hsb text-center text-md-start">
            <div className="row px-md-5 mx-md-5 px-3">
        <div className="font-35-below-banner fw-700 ps-0 lh-sm text-start">       
        Welcome to My Family MD, <span className="font-30-below-banner text-pri-hsb d-block"> Your Trusted Source for Primary Care </span> 
        </div>
        {/* <hr className="hr d-none d-md-block border-below" />
        <hr className="d-block d-md-none w-50 hr2"/> */}
        <div className="font-16-landing-page fw-500 pt-3 ps-0 text-start">
        At My Family MD, we understand that your health is your most valuable asset. That's why we're more than just a primary care clinic; we're your dedicated partners on the path to well-being. As Huntsville's premier internal medicine clinic, our mission is to elevate your health and, in turn, boost your life. We're here to provide comprehensive healthcare solutions, from preventive care to telehealth services and everything in between. 
        <div className="py-2" />
        Our team of primary care doctors is committed to your well-being, and we believe in cost-effective, patient-centered medical services. Whether you're seeking a preventive check-up, telemedicine consultation or need a primary care physician near you, we've got you covered. We go the extra mile to bridge distances with our telehealth doctor services, offering expert guidance and consultations through advanced technology. Our focus on preventive care empowers you to safeguard your well-being, optimize your health at every life stage, and enjoy peace of mind.  
        </div>
        </div>
        </section>
        
        <section className="container-fluid pt-4 pt-md-5 font-pri-hsb">
            <div className="row justify-content-center gx-4 gy-4">
                <div className="col-12 col-md-3 text-center">
                    <img className="w-25" src={Appointment} alt="Appointment" />
                    <div className="font-18-landing-page pt-3 fw-600">
                    Same-Day <br/> Appointments*
                    </div>
                </div>
                <div className="col-12 col-md-3 text-center">
                    <img className="w-25" src={MedicalTeam} alt="Medical Team" />
                    <div className="font-18-landing-page pt-3 fw-600">
                    Professional <br /> Healthcare Services
                    </div>
                </div>
                <div className="col-12 col-md-3 text-center">
                    <img className="w-25" src={MedicalCheckup} alt="Medical Checkup" />  
                    <div className="font-18-landing-page pt-3 fw-600">
                    Accepting <br /> New Patients    
                    </div>                  
                </div>
            </div>
        </section>

        <section className="container-fluid pt-4 pt-md-5 font-pri-hsb text-center text-md-start">
        <div className="row px-md-5 mx-md-5 px-3">
        <div className="font-16-landing-page fw-500 ps-0 text-start">
        At My Family MD, we pride ourselves on offering consultative referrals, ensuring that your unique health needs are addressed with precision and expertise. Our commitment to your health is unwavering, and we always accept new patients; our primary care providers are ready to provide the care and attention you deserve. 
         <div className="py-2" />
        Join My Family MD's wellness journey today and discover a world of medical knowledge, compassionate telemedicine, and telehealth services. Enhance your health and life – because you and your family deserve the best. 
        </div>
        </div>
        </section>

        {/* Mobile */}
        <section className="d-block d-xl-none container-fluid pt-4 pt-md-5 font-pri-hsb">
            <div className="row justify-content-evenly px-md-5 gy-4 mx-md-5">
                <div className="font-30-landing-page fw-700 text-uppercase text-center pt-3 pt-md-0">
                    Our Services
                    <hr className="hr border-below-center mx-auto" />
                </div>
                <div className="col-10 col-md-5 bg-light rounded-4 text-center px-4 py-5 shadow-landing-page">
                    <img className="w-25" src={Icon1} alt="Icon1" />
                    <Link className="td-none" to="/primary-care-clinic-huntsville">
                    <div className="pt-3 font-20-landing-page text-pri-hsb fw-700">
                    PRIMARY CARE
                    </div>
                    </Link>
                    <div className="text-start pt-3 font-16-landing-page">
                    Our primary care doctors in Huntsville, AL, provide comprehensive healthcare & cost-effective medical services. 
                    </div>
                </div>
                <div className="col-10 col-md-5 bg-light rounded-4 text-center px-4 py-5 shadow-landing-page">
                    <img className="w-25" src={Icon2} alt="Icon2" />
                    <Link className="td-none" to="/telemedicine-service-huntsville">
                    <div className="pt-3 font-20-landing-page text-pri-hsb fw-700">
                    TELEMEDICINE
                    </div>
                    </Link>
                    <div className="text-start pt-3 font-16-landing-page">
                    Bridge distances with virtual healthcare access. Get expert telemedicine service, guidance, and consultations using advanced technology. 
                    </div>
                </div>
                <div className="col-10 col-md-5 bg-light rounded-4 text-center px-4 py-5 shadow-landing-page">
                    <img className="w-25" src={Icon3} alt="Icon3" />
                    <Link className="td-none" to="/preventive-care-clinic-huntsville">
                    <div className="pt-3 font-20-landing-page text-pri-hsb fw-700">
                        PREVENTIVE CARE
                    </div>
                    </Link>
                    <div className="text-start pt-3 font-16-landing-page">
                    Safeguard your well-being with proactive measures. Optimize health at all life stages with our preventative care services & treatments. 
                    </div>
                </div>
                <div className="col-10 col-md-5 bg-light rounded-4 text-center px-4 py-5 shadow-landing-page">
                    <img className="w-25" src={Icon4} alt="Icon4" />
                    <Link className="td-none" to="/consultative-referrals-huntsville">
                    <div className="pt-3 font-20-landing-page text-pri-hsb fw-700">
                    CONSULTATIVE REFERRALS
                    </div>
                    </Link>
                    <div className="text-start pt-3 font-16-landing-page">
                    Access specialized care tailored to your unique needs. Address specific health concerns with precision and expertise. 
                    </div>
                </div>
            </div>
        </section>

        <section className="d-none d-xl-block container-fluid pt-4 pt-md-5 font-pri-hsb">
            <div className="row justify-content-evenly px-md-5 gy-4 mx-md-5 bg-services-landing-page pb-5 ">
                <div className="font-30-landing-page fw-700 text-uppercase text-center">
                    Our Services
                    <hr className="hr border-below-center mx-auto" />
                </div>
                <div className="col-landing-page bg-light rounded-4 text-center px-4 py-5 shadow-landing-page">
                    <img className="w-25" src={Icon1} alt="Icon1" />
                    <Link className="td-none" to="/primary-care-clinic-huntsville">
                    <div className="pt-3 font-20-landing-page text-pri-hsb fw-700">
                    PRIMARY CARE
                    </div>
                    </Link>
                    <div className="text-start pt-3 font-16-landing-page">
                    Our primary care doctors in Huntsville, AL, provide comprehensive healthcare & cost-effective medical services. 
                    </div>
                </div>
                <div className="col-landing-page bg-light rounded-4 text-center px-4 py-5 shadow-landing-page">
                    <img className="w-25" src={Icon2} alt="Icon2" />
                    <Link className="td-none" to="/telemedicine-service-huntsville">
                    <div className="pt-3 font-20-landing-page text-pri-hsb fw-700">
                    TELEMEDICINE
                    </div>
                    </Link>
                    <div className="text-start pt-3 font-16-landing-page">
                    Bridge distances with virtual healthcare access. Get expert telemedicine service, guidance, and consultations using advanced technology. 
                    </div>
                </div>
                <div className="col-landing-page bg-light rounded-4 text-center px-4 py-5 shadow-landing-page">
                    <img className="w-25" src={Icon3} alt="Icon3" />
                    <Link className="td-none" to="/preventive-care-clinic-huntsville">
                    <div className="pt-3 font-20-landing-page text-pri-hsb fw-700">
                        PREVENTIVE CARE
                    </div>
                    </Link>
                    <div className="text-start pt-3 font-16-landing-page">
                    Safeguard your well-being with proactive measures. Optimize health at all life stages with our preventative care services & treatments. 
                    </div>
                </div>
                <div className="col-landing-page bg-light rounded-4 text-center px-4 py-5 shadow-landing-page">
                    <img className="w-25" src={Icon4} alt="Icon4" />
                    <Link className="td-none" to="/consultative-referrals-huntsville">
                    <div className="pt-3 font-20-landing-page text-pri-hsb fw-700">
                    CONSULTATIVE REFERRALS
                    </div>
                    </Link>
                    <div className="text-start pt-3 font-16-landing-page">
                    Access specialized care tailored to your unique needs. Address specific health concerns with precision and expertise. 
                    </div>
                </div>
            </div>
        </section>
        
        <section className="container-fluid font-pri-hsb middle-bg-landing-page">
            <div className="row justify-content-center py-5 my-5">
                <div className="col-11 text-center py-md-5 my-md-5">
                {/* <div className="font-35-below-banner fw-400 text-capitalize text-center text-white lh-sm">
                Our dedicated healthcare professionals offer <br className="d-none d-md-block" /> preventive care, screenings, and more.
                </div> */}

                <div className="font-35-below-banner fw-400 text-capitalize text-center text-white lh-sm">
                    We provide the best primary care in Huntsville
                </div>
                
                <div className="fs-ss-30-btn  fw-700 text-uppercase text-center text-white pb-3 pt-3">
                    Book your appointment !
                </div>
                <a href="tel:+12564899741" target="_blank">
                    <button className="text-white fs-ss-20-btn px-lg-5 px-3 py-1 py-md-2 hover-pri  border-0 bg-sec rounded-5 fw-600 font-pri-hsb">
                        Call Us Now
                    </button>
                </a>
                </div>
            </div>
        </section>

        <section className="container-fluid px-md-5 pb-1 font-pri-hsb">
            <div className="row justify-content-evenly gy-4">
            <div className="font-30-landing-page fw-700 text-uppercase text-pri-hsb text-center">
                    Our Providers
                </div>
                <div className="col-8 col-md-3 text-center pattern-doctors py-5">
                    <img className="w-50" src={Doctor1} alt="Doctor 1" />
                    <Link className="text-decoration-none" to="/providers/#dr-iqbal-saeed" smooth>
                    <div className="text-pri-hsb font-18 fw-700 pt-4">
                    Dr. Iqbal Saeed
                    </div>
                    </Link>
                </div>
                <div className="col-8 col-md-3 text-center pattern-doctors py-5">
                    <img className="w-50" src={Doctor2} alt="Doctor 2" />
                    <Link className="text-decoration-none" to="/providers/#emily-klae-carnes" smooth>
                    <div className="text-pri-hsb font-18 fw-700 pt-4">
                    Emily Klae Carnes
                    </div>
                    </Link>
                </div>
                <div className="col-8 col-md-3 text-center pattern-doctors py-5">
                    <img className="w-50" src={Doctor3} alt="Doctor 3" />
                    <Link className="text-decoration-none" to="/providers/#morgan-johnson" smooth>
                    <div className="text-pri-hsb font-18 fw-700 pt-4">
                    Morgan Johnson
                    </div>
                    </Link>
                </div>
            </div>
        </section>

        <section className="container-fluid pt-5 pt-md-5 pb-4 pb-md-5 font-pri-hsb text-center text-md-start">
            <div className="row px-md-5 mx-md-5 px-3">
            {/* <div className="font-30-landing-page fw-700 ps-0 lh-sm">       
        Health Problems? Discover Solutions. <span className="text-pri-hsb d-block"> Embrace a Healthy Body, Mind, and Life  </span> 
        </div> */}

        <div className="font-30-landing-page fw-700 ps-0 lh-sm text-capitalize text-pri-hsb text-start">       
          Our experts provide complete preventive care
        </div>

       

        {/* <hr className="hr d-none d-md-block border-below" />
        <hr className="d-block d-md-none w-50 hr2"/> */}
        <div className="font-16-landing-page fw-500 pt-3 ps-0 text-start">
        Our mission is to be your trusted primary healthcare center, providing medical expertise and peace of mind. We prioritize your well-being so you can focus on what matters most in life. At My Family MD, we're equipped to address various health issues, from preventive care to specialized consultations. No matter your healthcare needs, we're here to provide timely, compassionate, and practical solutions. 
        <div className="py-2" />
        Ready to take the first step toward optimal health? Please schedule an appointment or call us today for same-day sick visits. Your well-being is our priority, and we can't wait to be your primary healthcare center.  
        </div>
        </div>
        </section>

        <section className="container-fluid bg-grey mt-4">
        <div className="row justify-content-center align-items-center gy-4">
            <div className="col-12 col-xl-5 text-center text-xl-start py-4 py-md-0">
        <h3 className="font-pri-hsb text-pri-hsb font-30-landing-page fw-700 text-capitalize lh-base pb-4">
        Keeping You and Your Family <br className="d-block d-lg-block"/>Healthy and Happy
        </h3>
        <a href="https://www.google.com/maps/place/Iqbal+Saeed+MD+LLC/@34.699686,-86.6030182,18z/data=!4m6!3m5!1s0x88626cea37639e9b:0x5c30f864a919d024!8m2!3d34.6994209!4d-86.6028594!16s%2Fg%2F1tfbjg5q" className="td-none text-pri-hsb " target="_blank">
        <button className=" lh-30 px-4 border-0  text-white hover-pri rounded-5 fw-600 font-pri-hsb">
            View on Google Map
        </button>
        </a>
            </div>
            <div className="col-12 col-xl-5 pb-3">
                <iframe className="w-100" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1640.1089022091344!2d-86.6030182!3d34.699686!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88626cea37639e9b%3A0x5c30f864a919d024!2sIqbal%20Saeed%20MD%20LLC!5e0!3m2!1sen!2s!4v1684468280661!5m2!1sen!2s" width="600" height="340" style={{border:"0px"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"/>
            </div>
        </div>
    </section>

        <section className="container-fluid landing-page-footer py-5">
            <div className="row justify-content-center text-white font-pri-hsb">
                <div className="col-md-10 col-11 text-start text-lg-center">
                    {/* <div className="font-35-landing-page fw-700 text-capitalize">
                    Elevate, Embrace, Thrive:  
                    </div> */}
                    <div className="font-30-landing-page fw-700 text-capitalize">
                    My Family MD Welcomes New Patients in Huntsville 
                    </div>
                    <div className="pt-4 font-16-landing-page" >
                        Our professional family doctors in Huntsville, AL, accept new patients. With accessible care, experienced physicians, and personalized approaches, we make prioritizing wellness a breeze. Schedule an appointment today and experience the difference in comprehensive healthcare, supporting your journey to lasting health.
                    </div>
                    <div className="font-16-landing-page fw-700 pt-2">
                    Welcome to a healthier, happier you.
                    </div>
                    <div className="pt-4 text-center">
                        <button className="btn px-5 py-1 rounded-5 bg-white">
                        <a href="tel:+12564899741" className="td-none text-sec-hsb"  target="_blank" > 
                    <span className="font-16-landing-page fw-700 fst-normal">(256)-489-9741 </span>
                    </a>
                        </button>
                    </div>
                    <div className="pt-5 font-16-landing-page" >
                    <b> Note: </b> *We make an effort to provide same-day appointments when necessary, but it's not our regular procedure.
                    </div>
                </div>
            </div>
        </section>
        </>
        
    )
};

export default LandingPage;