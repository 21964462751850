import React from "react"
import { Link } from "react-router-dom"



const CTA2 = () =>{
    return (
    <>
    <section className="container-fluid mb-4 mt-4 py-4 px-4">
    <div className="row justify-content-md-center">
    <div className="col-md-8 text-center text-white rounded-5 bg-pri py-5">
    <h3 className="font-pri-hsb font-25-btn fw-500 ">
        We are always stay in contact with our patients
        </h3>
        <h2 className="font-pri-hsb font-35-btn fw-800 text-uppercase">
        Contact our team members
        </h2>
        <Link to="/contact-us" className="">
        <button className=" mt-3 lh-30 text-white px-5 py-1 border-0 bg-sec rounded-3 fw-600 font-pri-hsb">
            Contact Us
        </button>
        </Link>
        </div>
            </div>
    </section>
    </>
    )
}

export default CTA2;