import React, {useEffect} from "react"
import Header from "../components/layout/Header"
import ServicesPara from "../components/services/ServicesPara";
import CTA from "../components/services/CTA";
import MiddleBannerMap from "../components/layout/MiddleBannerMap";
import OurInsurance from "../components/layout/OurInsurance";
import TakeActionToday from "../components/layout/TakeActionToday";
import Footer from "../components/layout/Footer";
import Img1 from "../assets/images/ourservices/primarycare/Img1.png"
import Img2 from "../assets/images/ourservices/primarycare/Img2.png"
import Banner from "../components/layout/Banner";
import BgImage from "../assets/images/ourservices/primarycare/Banner.png";
import { Helmet } from "react-helmet";
import {PrimaryCareSchema} from '../components/schemas/PrimaryCareSchema';


const PrimaryCare=()=> {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
        <Helmet>
        <title>Exceptional Primary Care Service in Huntsville, AL | My Family MD</title>
        <meta name="description" content="Choose leading primary care doctors in Huntsville, AL. Personalized care, disease prevention, comprehensive services. Prioritize your well-being. Schedule now!" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Exceptional Primary Care Service in Huntsville, AL | My Family MD" />
        <meta property="og:description" content="Choose leading primary care doctors in Huntsville, AL. Personalized care, disease prevention, comprehensive services. Prioritize your well-being. Schedule now!" />
        <meta property="og:url" content="https://myfamilymd.org/" />
        <meta property="og:site_name" content="myfamilymd" />
        <meta property="og:image" content="https://myfamilymd.org/og.png" />
        <meta property='og:image:width' content='1200' />
        <meta property='og:image:height' content='630' />
        <meta property="og:image:secure_url" content="https://myfamilymd.org/og.png" />
        <meta name="twitter:card" conent="summary_large_image" />
        <meta name="twitter:description" content="Choose leading primary care doctors in Huntsville, AL. Personalized care, disease prevention, comprehensive services. Prioritize your well-being. Schedule now!" />
        <meta name="twitter:title" content="Exceptional Primary Care Service in Huntsville, AL | My Family MD" />
        <meta name="twitter:image" content="https://myfamilymd.org/og.png" />

        <script className='structured-data-list' type="application/ld+json">{PrimaryCareSchema()}</script>


        </Helmet>
        <Header/>
        <Banner
        BgImage={BgImage}
        Alt="Top Primary Care Service In Huntsville"
        Title1="The exceptional"
        Title2="Primary Care Service"
        br={<br className="d-none d-lg-block"/>}
        Title2_2="In Huntsville, Al"
        Title3="Your Health, Our Priority" 
        />
        <ServicesPara
        Para1_1="As the initial point of contact with the healthcare system, primary care plays a vital role in providing individuals with the knowledge and tools necessary to achieve their health goals. While common ailments such as aches, pains, and colds are addressed, primary care doctors possess a broad range of knowledge to handle a variety of medical concerns. At My Family MD, you will get in contact with the best Primary care doctors in Huntsville, AL, highly trained and skilled in delivering first-contact, ongoing care to patients experiencing unexplained symptoms or health issues."
  
        Para1_2= "From disease prevention and health maintenance to patient education, primary care encompasses a wide scope of services across various healthcare settings. Choose My Family MD as your primary care clinic in Huntsville, AL, and experience the difference of having a dedicated healthcare team prioritizing your well-being. We are here to inspire and empower you to take control of your health, providing exceptional primary care services tailored to your individual needs. Contact us today to schedule an appointment and start your journey to better health with My Family MD. "
        />
        <CTA/>

        <section className="container-fluid">
        <div className="row" style={{backgroundColor: "#f4e9e4"}}>
            <div className="col-12 col-md-5 section-img-1" style={{backgroundImage:`url(${Img1})`}}>
            <img className='img-fluid invisible' alt="Benefits of primary care huntsville, al" src={Img1} />    
            </div>
            <div className="col-12 align-self-center col-md-7 py-5 ps-xl-5"> 
            <div className=" px-xl-5  px-3">
            <h2 className="font-35 font-pri-hsb"> Experience the <span className="fw-800 text-pri-hsb"> Benefits of Primary Care in Huntsville, AL </span> </h2>
            <hr className="hr w-25" />
            <p className="font-17 font-sec2-hsb fw-500">
            Primary care is more than just a service; it forms the foundation of your healthcare journey, establishing a trusted bond between you and your primary care doctor. By choosing primary care, you gain continuous access to comprehensive healthcare that not only improves your well-being but also reduces costs. Instead of relying on emergency departments for non-life-threatening conditions, primary care saves you time and minimizes expenses. <br/><br/>
            With a primary care doctor in Huntsville, who understands your medical history, wellness goals, and concerns, you receive personalized care that extends beyond addressing immediate ailments. Whether it's managing chronic conditions like diabetes or asthma or closely monitoring your health for early detection of changes, your dedicated primary care doctor collaborates with you to adjust treatment plans and empower you to live a healthier life. <br/><br/>
            At My Family MD, we are committed to providing exceptional primary care in Huntsville, AL, ensuring that your health is in capable hands. Choose the benefits of primary care and embark on a journey to thriving and well-being.
            </p>
            </div>
            </div>
        </div>
        <div className="row" style={{backgroundColor: "#f6f6f6"}}>
        <div className="d-block d-md-none col-12 col-md-5 section-img-2" style={{backgroundImage:`url(${Img2})`}}>
            <img className='img-fluid invisible' alt="Primary care services in huntsville" src={Img2} />    
            </div>
            <div className="col-12 align-self-center col-md-7 py-5 ps-xl-5"> 
            <div className=" px-xl-5  px-3">
            <h2 className="font-35 font-pri-hsb"> Enhancing Your <span className="fw-800 text-pri-hsb"> Well-being Through Comprehensive Primary Care: </span> </h2>
            <hr className="hr w-25" />
            <p className="font-17 font-sec2-hsb fw-500">
            When it comes to your healthcare needs, the primary care doctors at My Family MD in Huntsville, AL, offer a wide range of diagnostic, treatment, and procedural services to ensure your well-being. From cancer screenings and cardiovascular health assessments to diabetes tests and prescription medications, we provide the essential services you require. Our primary care doctors are also skilled in administering vaccinations, performing minor surgical procedures such as wound suturing, and providing services like ultrasounds, casting, splinting, and bracing. <br/> <br/>
            Establishing trust is the cornerstone of our primary care approach, ensuring you receive the maximum benefits from our services. What's even better? We bring exceptional primary care right to Huntsville, AL. Our priority is preventing and treating adult diseases, including diabetes, hypertension, arthritis, asthma, COPD, and common infections like the cold because your health and well-being are our utmost concerns. Experience the difference we can make in your life through our comprehensive primary care services in Huntsville, AL.
            </p>
            </div>
            </div>
            <div className="d-none d-md-block col-12 col-md-5 section-img-2" style={{backgroundImage:`url(${Img2})`}}>
            <img className='img-fluid invisible' alt="Primary care services in huntsville" src={Img2} />    
            </div>
        </div>
        </section>
        <div className="pt-4">
        </div>
        <MiddleBannerMap/>
        <OurInsurance/>
        <TakeActionToday/>
        <Footer/>
        </>
        
    )
};

export default PrimaryCare;