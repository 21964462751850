import React from 'react';
import {Routes, Route, Navigate} from 'react-router-dom';
import Home from './pages/Home'
import AboutUs from './pages/AboutUs';
import Services from './pages/Services';
import PrimaryCare from './pages/PrimaryCare';
import Telemedicine from './pages/Telemedicine';
import PreventiveCare from './pages/PreventiveCare';
import ConsultativeReferrals from './pages/ConsultativeReferrals';
import Forms from './pages/Forms';
import ContactUs from './pages/ContactUs';
import NotFound from '../src/pages/Error'
import "./assets/css/style.css"
import Providers from './pages/Providers';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap'
import LandingPage from './pages/LandingPage';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";


const Blogs = React.lazy(() => import('./pages/Blogs'));
const Blog = React.lazy(() => import('./pages/Blog'));



function App() {
  return (
    <>
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/about-us" element={<AboutUs/>} />
          <Route path="/our-services" element={<Services/>} />
          <Route path="/primary-care-clinic-huntsville" element={<PrimaryCare/>} />
          <Route path="/telemedicine-service-huntsville" element={<Telemedicine/>} />
          <Route path="/preventive-care-clinic-huntsville" element={<PreventiveCare/>} />
          <Route path="/consultative-referrals-huntsville" element={<ConsultativeReferrals/>} />
          <Route path="/family-healthcare-services" element={<LandingPage/>} />

          <Route path="/providers" element={<Providers/>} />
          <Route path="/forms" element={<Forms/>} />
          <Route path="/contact-us" element={<ContactUs/>} />
          {/* <Route path="/blogs" element={<Blogs/>} />
          <Route path="/blog/:id" element={<Blog/>} /> */}
          <Route path='/primary-care' element={ <Navigate to="/primary-care-clinic-huntsville" /> }/>
          <Route path='/telemedicine' element={ <Navigate to="/telemedicine-service-huntsville" /> }/>
          <Route path='/preventive-care' element={ <Navigate to="/preventive-care-clinic-huntsville" /> }/>
          <Route path='/consultative-referrals' element={ <Navigate to="/consultative-referrals-huntsville" /> }/>

          <Route path="*" element={<NotFound/>}  />
        </Routes>
  </>
  );
}

export default App;
