import React from "react"
import { Link } from "react-router-dom"



const CTA = () =>{
    return (
    <>
    <section className="container-fluid mb-5 px-4">
    <div className="row justify-content-md-center">
    <div className="col-md-8 text-center text-white rounded-5 bg-pri py-5 ps-md-5 ps-md-0">
    <h3 className="font-pri-hsb font-25-btn fw-500 text-capitalize">
        Let's build a healthier tomorrow together!
        </h3>
        <h2 className="font-pri-hsb font-35-btn fw-800 text-uppercase">
        Make an appointment
        </h2>
        <a href="tel:+12564899741" target="_blank" className="px-3">
        <button className=" mt-3 lh-30 text-white px-5 border-0 bg-sec rounded-3 fw-600 font-pri-hsb">
        &nbsp;  Call Now &nbsp;
        </button>
        </a>
        <Link to="/contact-us" className="px-3">
        <button className=" mt-3 lh-30 text-white px-5 border-0 bg-sec rounded-3 fw-600 font-pri-hsb">
            Contact Us
        </button>
        </Link>
        </div>
            </div>
    </section>
    </>
    )
}

export default CTA;