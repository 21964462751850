import React from "react"
import { Link } from "react-router-dom"
import Banner_mb from '../../assets/images/aboutus/Banner_Mb.png'

const Banner = (props) =>{
    return (
    <>
    <section className="container-fluid px-0 bg-sample mb-md-5 mb-4">
        <img className="w-100 d-none d-xl-block" src={props.BgImage} alt={props.alt} />
        <img className="w-100 d-block d-xl-none"  src={Banner_mb} alt="" />
        <div className="banner-text ms-md-5 mx-3">
        <h3 className="font-30-banner text-white font-pri-hsb d-flex ">
            {props.Title1} <hr className="hr3 d-none d-md-block" />
        </h3>
       <h1 className="font-46-banner text-capitalize font-pri-hsb fw-700 font-46 text-white "> {props.Title2} {props.br} {props.Title2_2}
       <h1 className="fw-400 text-white font-30-banner"> {props.Title3} <br className="d-none d-md-block"/> {props.Title3_3}</h1>
       </h1> 
        </div>
    </section>
    </>
    )
}

export default Banner;