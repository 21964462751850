import React from "react"
import { Link } from "react-router-dom"



const BookNow = () =>{
    return (
    <>
    <section className="container-hsb mb-md-5 mb-3 px">
    <div className="row justify-content-center">
    <div className="col-md-8 text-center text-white rounded-5 bg-pri py-5 ps-md-5 ps-md-0">
    <h3 className="font-pri-hsb font-25-btn fw-500 text-capitalize">
        Let's build a healthier tomorrow together!
        </h3>
        <h2 className="font-pri-hsb font-35-btn fw-800 text-uppercase">
            Accepting new patients
        </h2>
        <a href="tel:+12564899741" target="_blank">
            <button className="mt-3 lh-30 text-white px-5 border-0 bg-sec rounded-3 fw-600 font-pri-hsb">
            Book Now
        </button>
        </a>
        </div>
            </div>
    </section>
    </>
    )
}

export default BookNow;