import React from "react"

const MiddleBannerMap = () =>{
    return (
    <>
    <section className="container-fluid bg-pri">
        <div className="ps-xl-5 row justify-content-center align-items-center gy-4">
            <div className="col-12 col-xl-6 text-center text-xl-start py-4 py-md-0 ps-xl-5">
        <h3 className="font-pri-hsb text-white font-30 fw-600 text-capitalize pe-xl-5 lh">
        Keeping You and Your Family <br className="d-block d-lg-block"/>Healthy and Happy
        </h3>
        <a href="https://www.google.com/maps/place/Iqbal+Saeed+MD+LLC/@34.699686,-86.6030182,18z/data=!4m6!3m5!1s0x88626cea37639e9b:0x5c30f864a919d024!8m2!3d34.6994209!4d-86.6028594!16s%2Fg%2F1tfbjg5q" className="td-none text-pri-hsb " target="_blank">
        <button className=" mt-md-4 lh-30 px-4 border-0 bg-sec text-white rounded-3 fw-600 font-pri-hsb">
            View on Google Map
        </button>
        </a>
            </div>
            <div className="col-12 col-xl-6 pb-3">
                <iframe className="w-100" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1640.1089022091344!2d-86.6030182!3d34.699686!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88626cea37639e9b%3A0x5c30f864a919d024!2sIqbal%20Saeed%20MD%20LLC!5e0!3m2!1sen!2s!4v1684468280661!5m2!1sen!2s" width="600" height="380" style={{border:"0px"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"/>
            </div>
        </div>
    </section>
    </>
    )
}

export default MiddleBannerMap;