import React from 'react';
import { Link } from "react-router-dom"
import { ChevronRight, GeoAltFill, PhoneFill, TelephoneFill, Whatsapp } from "react-bootstrap-icons"
import Navbar from 'react-bootstrap/Navbar';
import { useLocation } from "react-router-dom";
import HeaderLogo from "../../assets/images/home/HeaderLogo.png"
import Offcanvas from 'react-bootstrap/Offcanvas';


const Header = () => {
  const location = useLocation();

  const { pathname } = location;  
  const splitLocation = pathname.split("/");

  return (
    <>
    <div>
    <section className="container-fluid bg-white ps-lg-4 pe-lg-5 py-lg-1 py-2">
      <div className="row text-capitalize text-pri-hsb justify-content-lg-center font-pri-hsb ps-lg-4 pe-lg-3">
        <div className="d-none d-md-block col-12 col-lg-4 col-xl-5 ">
          <h3 className="font-16-upper-header fw-700 pt-2">
            We are accepting new patients
          </h3>
        </div>
        <div className="col-12 col-lg-8 col-xl-7 text-md-start text-lg-end pt-lg-1 pt-xl-2">
        <a href="tel:+12564899741" className="td-none text-pri-hsb pe-5"  target="_blank" > 
        {/* <i className="bi bi-telephone-fill"> */}
        <TelephoneFill/>
        <span className="font-16-upper-header fw-600 fst-normal ps-2">(256)-489-9741 </span>
        {/* </i>  */}
        </a> <br className="d-block d-md-none"/>
        <a href="https://www.google.com/maps/place/Iqbal+Saeed+MD+LLC/@34.699686,-86.6030182,18z/data=!4m6!3m5!1s0x88626cea37639e9b:0x5c30f864a919d024!8m2!3d34.6994209!4d-86.6028594!16s%2Fg%2F1tfbjg5q" className="td-none text-pri-hsb" target="_blank"> 
        {/* <i className="bi bi-geo-alt-fill">  */}
        <GeoAltFill />
        <span className="font-16-upper-header fw-600 fst-normal"> 2227 Drake Ave. Suite 7A Huntsville, AL 35805</span>
        {/* </i>  */}
        </a>
        </div>
        </div>
    </section>
      <Navbar expand="lg" className="bg-pri py-0" id="website-nav" style={{borderBottom:"3px solid #9d0009"}}>
        <div className='container-fluid px-lg-5'>
            <Navbar.Brand className='navbar-brand bg-white ps-3' style={{borderTop:"1px solid #00524B"}}>
            <Link className="navbar-brand bg-white" to="/"> 
            <img className="home-logo-width" id="logo" src={HeaderLogo}/> 
            </Link>
            </Navbar.Brand>
                
                
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-lg`} className='rounded-0 bg-white' style={{border:"none"}} />
            <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-lg`}
            aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
            placement="start"
            className="navbar-offcanvas"
            >
            <Offcanvas.Header closeButton>

                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-lg`}>
                    <Link
                    className="flex-wrap navbar-brand d-flex align-items-baseline"
                    to="/"
                    >
                    <img
                        src={HeaderLogo}
                        alt="Logo"
                        className="header-logo-mb"
                        style={{height: "45px"}}
                    />
                    </Link>
                </Offcanvas.Title>
            </Offcanvas.Header>
            {/* <hr /> */}
            <Offcanvas.Body>
            <ul className="d-block d-lg-none navbar-nav font-pri-hsb">
        <li className="nav-item">
          <Link className="text-black text-decoration-none lh-lg" aria-current="page" to="/">Home</Link>
        </li>
        <li className="nav-item ">
          <Link  className="text-black text-decoration-none lh-lg" aria-current="page" to="/about-us">About Us</Link>
        </li>
        <li className="nav-item ">
          <Link  className="text-black text-decoration-none lh-lg" aria-current="page" to="/providers">Providers</Link>
        </li>
        <li className="nav-item right-menu">
          <div class="btn-group dropdown">
            <Link  className="text-black text-decoration-none lh-lg " to="/our-services" role="button">
              Services
            </Link>
            <Link class="btn px-4 dropdown-toggle dropdown-toggle-split border-0 text-black text-decoration-none lh-sm " data-bs-toggle="dropdown-menu" aria-expanded="false">
              <span class="visually-hidden">Toggle Dropdown</span>
              </Link>
            </div>
            <ul className="dropdown-menu">
            <li><Link className="dropdown-item" to="/primary-care-clinic-huntsville" >Primary Care</Link></li>
            <li><Link className="dropdown-item" to="/telemedicine-service-huntsville" >Telemedicine</Link></li>
            <li><Link className="dropdown-item" to="/preventive-care-clinic-huntsville" >Preventive Care</Link></li>
            <li><Link className="dropdown-item" to="/consultative-referrals-huntsville" >Consultative Referrals</Link></li>
            </ul>
        </li>

        {/* <li className="nav-item">
          <div className="btn-group">
            <Link  className="text-black text-decoration-none lh-lg " to="/our-services" role="button">
              Services
            </Link>
            <button type="button" className="dropdown-toggle dropdown-toggle-split px-4 rounded-0 bg-white border-0" data-bs-toggle="dropdown" aria-expanded="false">
            <span className="visually-hidden">Toggle Dropdown</span>
            </button>
            <ul className="dropdown-menu">
            <li><Link className="dropdown-item" to="/primary-care-clinic-huntsville" >Primary Care</Link></li>
            <li><Link className="dropdown-item" to="/telemedicine-service-huntsville" >Telemedicine</Link></li>
            <li><Link className="dropdown-item" to="/preventive-care-clinic-huntsville" >Preventive Care</Link></li>
            <li><Link className="dropdown-item" to="/consultative-referrals-huntsville" >Consultative Referrals</Link></li>
            </ul>
            </div>
            </li> */}


            
        {/* <li className="nav-item right-menu">
<div className="btn-group">
<Link  className="text-black text-decoration-none lh-lg " to="/our-services" role="button">
  Useful Links
</Link>
  <Link className="btn px-4 dropdown-toggle dropdown-toggle-split border-0 text-black text-decoration-none lh-sm " data-bs-toggle="dropdown-menu" aria-expanded="false">
    <span className="visually-hidden">Toggle Dropdown</span>
  </Link>
</div>
          <ul className="dropdown-menu ">
            <a className="dropdown-item  " target="_blank" href="https://www.cdc.gov/"><li>CDC</li></a>
          </ul>
        </li> */}

        
        {/* <li className="nav-item right-menu">
<div className="btn-group dropdown">
<Link  className="text-black text-decoration-none lh-lg " to="/our-services" role="button">
  Services
</Link>
  <Link className="btn px-4 dropdown-toggle dropdown-toggle-split border-0 text-black text-decoration-none lh-sm " data-bs-toggle="dropdown-menu" aria-expanded="false">
    <span className="visually-hidden">Toggle Dropdown</span>
  </Link>
</div>
<ul className="dropdown-menu">
          <li> <Link className="dropdown-item" to="/primary-care-clinic-huntsville" >Primary Care</Link></li>
            <li><Link className="dropdown-item" to="/telemedicine-service-huntsville" >Telemedicine</Link></li>
            <li><Link className="dropdown-item" to="/preventive-care-clinic-huntsville" >Preventive Care</Link></li>
            <li><Link className="dropdown-item" to="/consultative-referrals-huntsville" >Consultative Referrals</Link></li>
          </ul>
</li> */}
        <li className="nav-item ">
          <Link  className="text-black text-decoration-none lh-lg" aria-current="page" to="/forms">Forms</Link>
        </li>


        <li className="nav-item right-menu">
<div className="btn-group dropdown">
<Link  className="text-black text-decoration-none lh-lg " to="/our-services" role="button">
  Useful Links
</Link>
  <Link className="btn px-4 dropdown-toggle dropdown-toggle-split border-0 text-black text-decoration-none lh-sm " data-bs-toggle="dropdown-menu" aria-expanded="false">
    <span className="visually-hidden">Toggle Dropdown</span>
  </Link>
</div>
          <ul className="dropdown-menu ">
            <a className="dropdown-item  " target="_blank" href="https://www.cdc.gov/"><li>CDC</li></a>
            <li><a className="dropdown-item " target="_blank" href="https://www.alabamapublichealth.gov/index.html">ADPH</a></li>
            <li><a className="dropdown-item " target="_blank" href="https://www.nih.gov/">NIH</a></li>
            <li><a className="dropdown-item " target="_blank" href="https://www.ama-assn.org/">AMA</a></li>
          </ul>
        </li>
        <li className="nav-item bs-btn py-2 py-md-0">
            <a href="tel:+12564899741"  target="_blank" > 
            <button className="text-capitalize lh-30 px-md-5 border-0 text-white rounded-2 fw-700 bg-pri w-100">  Call Now </button>
            </a>
        </li>
      </ul>

      {/* Desktop */}
      <ul className="d-none d-lg-flex navbar-nav ms-auto">
        <li className="nav-item">
          <Link className="nav-link" aria-current="page" to="/">Home</Link>
        </li>
        <li className="nav-item ">
          <Link  className="nav-link" aria-current="page" to="/about-us">About Us</Link>
        </li>
        <li className="nav-item ">
          <Link  className="nav-link" aria-current="page" to="/providers">Providers</Link>
        </li>
        <li className="nav-item right-menu">
          <Link  className="nav-link dropdown-toggle" to="/our-services" role="button">
            Services
          </Link>
          <ul className="dropdown-menu">
          <li> <Link className="dropdown-item" to="/primary-care-clinic-huntsville" >Primary Care</Link></li>
            <li><Link className="dropdown-item" to="/telemedicine-service-huntsville" >Telemedicine</Link></li>
            <li><Link className="dropdown-item" to="/preventive-care-clinic-huntsville" >Preventive Care</Link></li>
            <li><Link className="dropdown-item" to="/consultative-referrals-huntsville" >Consultative Referrals</Link></li>
          </ul>
        </li>
        <li className="nav-item ">
          <Link  className="nav-link" aria-current="page" to="/forms">Forms</Link>
        </li>
        <li className="nav-item right-menu">
          <Link  className="nav-link dropdown-toggle" to="" role="button" aria-expanded="false">
            Useful Links
          </Link>
          <ul className="dropdown-menu ">
            <a className="dropdown-item  " target="_blank" href="https://www.cdc.gov/"><li>CDC</li></a>
            <li><a className="dropdown-item " target="_blank" href="https://www.alabamapublichealth.gov/index.html">ADPH</a></li>
            <li><a className="dropdown-item " target="_blank" href="https://www.nih.gov/">NIH</a></li>
            <li><a className="dropdown-item " target="_blank" href="https://www.ama-assn.org/">AMA</a></li>
          </ul>
        </li>
        <li className="nav-item bs-btn font-16-upper-header py-2 py-md-0">
            <a href="tel:+12564899741"  target="_blank" > 
            <button className="text-capitalize lh-30 px-md-5 border-0 text-pri-hsb font-pri-hsb rounded-2 fw-700 bg-white w-100">  Call Now </button>
            </a>
        </li>
      </ul>
            </Offcanvas.Body>
            </Navbar.Offcanvas>
            </div>        
            </Navbar>
        </div>
    </>
  )
}

export default Header;