import React from "react"
import { Link } from "react-router-dom"
import PrimaryCare from "../../assets/images/home/PrimaryCare.png"
import Telemedicine from "../../assets/images/home/Telemedicine.png"
import PreventiveCare from "../../assets/images/home/PreventiveCare.png"
import ConsultativeReferrals from "../../assets/images/home/ConsultativeReferrals.png"


const OurServices = () =>{
    return (
    <>
    <section className="container-fluid mb-5">
        <div className="row gy-4 justify-content-center">
            
            <div className="col-11 col-md-4 col-xl-2 text-center bg-white  rounded-5 shadow pt-4 pb-2">
            <div className="pb-4">
                <img src={PrimaryCare} alt="Primary Care in huntsville" />
                </div>
                <Link className="td-none" to="/primary-care-clinic-huntsville">
                <h3 className="font-pri-hsb text-black font-20 fw-bold">
                PRIMARY CARE
                </h3>
                </Link>
                <p className="text-start font-sec2-hsb font-14 text-black px-3">
                Primary care is meant to be the primary source of healthcare while maintaining a long-term relationship with your doctor. In addition, it improves access to healthcare, reduces expenses, and enhances the quality of your health.
                </p>
            </div>

            <div className="col-11 col-md-4 col-xl-2 text-center bg-white  rounded-5 shadow pt-4 pb-2 ms-md-4 ms-xl-4">
            <div className="pb-4">
                <img src={Telemedicine} alt="telemedicine in huntsville" />
                </div>
                <Link className="td-none" to="/telemedicine-service-huntsville">
                <h3 className="font-pri-hsb text-black font-20 fw-bold">
                TELEMEDICINE
                </h3>
                </Link>
                <p className="text-start font-sec2-hsb font-14 text-black px-3" >
                We utilize telemedicine services for some hardship circumstances. Simply put together, in telemedicine, patients have the opportunity to communicate with a healthcare provider using technology.
                </p>
            </div>

            <div className="col-11 col-md-4 col-xl-2 text-center bg-white  rounded-5 shadow pt-4 pb-2 ms-xl-4 me-md-4">
            <div className="pb-4">
                <img src={PreventiveCare} alt="preventive care in huntsville" />
                </div>
                <Link className="td-none" to="/preventive-care-clinic-huntsville">
                <h3 className="font-pri-hsb text-black font-20 fw-bold">
                PREVENTIVE CARE
                </h3>
                </Link>
                <p className="text-start font-sec2-hsb font-14 text-black px-3" >
                Preventive healthcare aims to minimize disease burden and associated risk factors by avoiding illness. So, it is safe to say that Preventive treatments can be employed at any stage of life.
                </p>
            </div>

            <div className="col-11 col-md-4 col-xl-2 text-center bg-white  rounded-5 shadow pt-4 pb-2">
            <div className="pb-4">
                <img src={ConsultativeReferrals} alt="Consultative referrals in huntsville" />
                </div>
                <Link className="td-none" to="/consultative-referrals-huntsville">
                <h3 className="font-pri-hsb text-black font-20 fw-bold">
                CONSULTATIVE REFERRALS
                </h3>
                </Link>
                <p className="text-start font-sec2-hsb font-14 text-black px-3" >
                Nowadays, seeing condition-specific patients who need individual, special services are essential to increase health care practices.
                </p>
            </div>
        </div>
    </section>
    </>
    )
}

export default OurServices;