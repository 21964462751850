import React from "react"
import OurValuesImg from "../../assets/images/aboutus/OurValues1.png"
import Banner2 from "../../assets/images/aboutus/Banner2.png"


const OurValues = () =>{
    return (
    <>
    <section className="container-fluid mb-md-5 mb-3">
        <div className="row justify-content-end Banner2" style={{backgroundImage:`url(${Banner2})`}}>
            <div className="col-12 col-md-4 py-md-5 py-3 text-center">
            <img className="d-none d-md-block w-100" src={OurValuesImg} alt="Our Values" />
                <img className="d-block d-md-none bg-light w-50" src={OurValuesImg} alt="Our Values" />
                </div>
            <div className="col-12 col-md-6">
            </div>
        </div>
    </section>
    </>
    )
}

export default OurValues;