import React from "react"
import { Link } from "react-router-dom"
import BgImage from "../../assets/images/home/Banner.png";
import BgImageMB from "../../assets/images/home/Bannermb.png";


const HomeBanner = () =>{
    return (
    <>
        <section className="container-fluid px-0 bg-sample mb-3">
        <img className="w-100 d-none d-md-block " src={BgImage} alt="Internal medicine huntsville" />
        <img className="w-100 d-block d-md-none " src={BgImageMB} alt="Internal medicine huntsville" />
        <div className="banner-text-home ps-xl-5 ps-3">
        <h1 className="font-20-banner-home font-pri-hsb d-md-flex d-none ">
        Huntsville's Premier <br className="d-block d-md-none"/> Internal Medicine Clinic 
        </h1>
        <h1 className="font-46-banner-home text-capitalize font-pri-hsb fw-700 text-pri-hsb d-block d-md-none">
        Huntsville's <br className="d-block d-md-none"/> Premier Internal <br className="d-block d-md-none"/>  Medicine Clinic 
        </h1>

       <h3 className="font-46-banner-home text-capitalize font-pri-hsb fw-700 text-pri-hsb font-40 d-md-block d-none">
       Where Health and <br className=""/> Family Thrive
       </h3> 
       <a className="" href="tel:+12564899741">
        <button className=" font-16-banner py-md-2 py-1 my-md-3 px-md-4 px-3 border-0 bg-sec text-white rounded-3 text-uppercase fw-600 font-pri-hsb">
           Call Now
        </button>
        </a> 
        </div>
    </section>
    </>
    )
}

export default HomeBanner;