import React from "react"

const DoctorBanner = (props) =>{
    return (
        <>
        {/* 1st Section Content */}
        <div id={props.IDDoctor} className="container-fluid py-5 shadow mb-4"  style={{backgroundColor: "#f2f2f2"}} >
  <div className="row justify-content-center align-items-center">
  <div className="d-none d-md-block col-11 col-md-4 col-xl-3 ">
  <img className="w-100" src={props.Img} alt="Dr" />
    </div>
    <div className="col-11 col-md-6 ps-md-5">
    <div className="pt-xl-2 pb-xl-5">
        <h3 className="font-pri-hsb font-25 fw-600 text-pri-hsb">
        {props.SubTitle} 
        </h3>
        <h2 className="font-pri-hsb font-35 fw-500">
        {props.Title1} <span className="text-pri-hsb fw-800"> {props.Title2} </span>
        </h2>
        <hr className="hr w-50" />
        <p className="font-sec2-hsb font-17 fw-500">
            {props.Para}
        </p>
        {props.Button}
    </div>
    
    </div>
    <div className="text-center col-11 col-md-5 d-block d-md-none mt-5">
        <img className="w-75" src={props.Img} alt="Dr" />
    </div>
  </div>
</div>        
        </>

    )
}

export default DoctorBanner;