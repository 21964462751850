import React, {useEffect} from "react"
import Header from "../components/layout/Header"
import ServicesPara from "../components/services/ServicesPara";
import CTA from "../components/services/CTA";
import MiddleBannerMap from "../components/layout/MiddleBannerMap";
import OurInsurance from "../components/layout/OurInsurance";
import TakeActionToday from "../components/layout/TakeActionToday";
import Footer from "../components/layout/Footer";
import Img1 from "../assets/images/ourservices/preventivecare/Img1.png"
import Img2 from "../assets/images/ourservices/preventivecare/Img2.png"
import Img3 from "../assets/images/ourservices/preventivecare/Img3.png"
import BgImage from "../assets/images/ourservices/preventivecare/Banner.png";
import Banner from "../components/layout/Banner";
import { Helmet } from "react-helmet";
import {PreventiveCareSchema} from '../components/schemas/PreventiveCareSchema';


const PreventiveCare=()=> {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
        <Helmet>
        <title>Top Preventive Care Services in Huntsville, AL | My Family MD</title>

        <meta name="description" content="Experience the power of preventive care in Huntsville, AL. Safeguard health, detect early, prioritize well-being. Schedule now!" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Top Preventive Care Services in Huntsville, AL | My Family MD" />
        <meta property="og:description" content="Experience the power of preventive care in Huntsville, AL. Safeguard health, detect early, prioritize well-being. Schedule now!" />
        <meta property="og:url" content="https://myfamilymd.org/" />
        <meta property="og:site_name" content="myfamilymd" />
        <meta property="og:image" content="https://myfamilymd.org/og.png" />
        <meta property='og:image:width' content='1200' />
        <meta property='og:image:height' content='630' />
        <meta property="og:image:secure_url" content="https://myfamilymd.org/og.png" />
        <meta name="twitter:card" conent="summary_large_image" />
        <meta name="twitter:description" content="Experience the power of preventive care in Huntsville, AL. Safeguard health, detect early, prioritize well-being. Schedule now!" />
        <meta name="twitter:title" content="Top Preventive Care Services in Huntsville, AL | My Family MD" />
        <meta name="twitter:image" content="https://myfamilymd.ae/og.png" />

        <script className='structured-data-list' type="application/ld+json">{PreventiveCareSchema()}</script>

        </Helmet>
        <Header/>
        <Banner
        Alt="Preventive Care Service In Huntsville"
        BgImage={BgImage}
        Title1="Top & Professional"
        Title2="Preventive Care"
        br={<br className="d-none d-md-block"/>}
        Title2_2="in Huntsville, Al"
        Title3="Prevention is the best prescription."
        />
        <ServicesPara
        Title1_1="The Power of"
        Title1_2="Prevention"

        Para1_1="The vitality of our well-being is essential for a fulfilling life. Being immune to diseases grants us more time to dedicate to the things that bring us joy. However, achieving excellent health goes beyond treating current symptoms; it lies in the power of prevention. That's where preventive care services in Huntsville, AL come into play, serving as a vital component of a happy, healthy, and active lifestyle. By focusing on minimizing the burden of disease and associated risk factors, preventive healthcare aims to prevent illness before it occurs. Preventtive care services in Huntsville, Al are not limited to a specific stage of life or disease spectrum; they can be employed throughout one's lifetime to prevent further deterioration over time."
        
        Title2_1="Enhancing Well-being"
        Title2_2="Through Preventive Care"
        
        Para2="Chronic diseases, a leading cause of death and disability worldwide, contribute to escalating healthcare costs. Embracing preventive care as part of a community health plan is crucial. This includes clinical preventative treatments and screening tests that help identify and avoid potential health issues downstream. By improving health outcomes, preventive care plays a pivotal role in promoting individual well-being and enhancing societal health. Experience the benefits of preventive care services and take proactive steps toward a healthier future today."
        />
        <CTA/>

        <section className="container-fluid">
        <div className="row" style={{backgroundColor: "#f4e9e4"}}>
            <div className="col-12 col-md-5 section-img-1" style={{backgroundImage:`url(${Img1})`}}>
            <img className='img-fluid invisible' alt="Preventive Care Clinic in Huntsville" src={Img1} />    
            </div>
            <div className="col-12 align-self-center col-md-7 py-5 ps-xl-5"> 
            <div className=" px-xl-5  px-3">
            <h3 className="font-35 font-pri-hsb"> The First <span className="fw-800 text-pri-hsb"> Line of Defense: </span> </h3>
            <hr className="hr w-25" />
            <p className="font-17 font-sec2-hsb fw-500">
            The most important thing here is to safeguard your health. Vaccinations, accident prevention measures, nutritional medicine, and sports medicine are some of the choices accessible. In the department of dentistry, preventative measures are also a part of essential preventive medicine. Regular professional teeth cleaning, for example, is meant to prevent tooth decay and gum disorders such as periodontitis because a healthy person also has a beautiful smile.
            </p>
            </div>
            <div className=" px-xl-5  px-3">
            <h3 className="font-35 font-pri-hsb"> Vaccinations <span className="fw-800 text-pri-hsb"> are a Must: </span> </h3>
            <hr className="hr w-25" />
            <p className="font-17 font-sec2-hsb fw-500">
            Vaccines have a critical role in the prevention of infectious illnesses. For instance, the standard flu vaccine protects against diseases such as measles, diphtheria, and other viruses. However, vaccinations can be a frightening prospect for some people; they have been declared safe in studies. Immunity to certain illnesses must be pushed to achieve vigorously as the success of smallpox vaccinations is a considerable example to quote.
            </p>
            </div>
            </div>
        </div>
        <div className="row" style={{backgroundColor: "#f6f6f6"}}>
        <div className="d-block d-md-none col-12 col-md-5 section-img-2" style={{backgroundImage:`url(${Img2})`}}>
            <img className='img-fluid invisible' alt="Preventive Care Doctor in Huntsville" src={Img2} />    
            </div>
            <div className="col-12 align-self-center col-md-7 py-5 ps-xl-5"> 
            <div className=" px-xl-5  px-3">
            <h2 className="font-35 font-pri-hsb"> How Do <span className="fw-800 text-pri-hsb"> Preventive Care Services Work? </span> </h2>
            <hr className="hr w-25" />
            <p className="font-17 font-sec2-hsb fw-500">
            Preventive care services are crucial in maintaining our overall health and well-being. It operates through three primary approaches: primary prevention, secondary prevention, and tertiary prevention.
            <ul>
            <li>Primary prevention focuses on treating chronic illnesses early by reducing the risk factors contributing to their development. This involves making lifestyle changes and reducing exposure to harmful elements. For instance, adopting a healthy diet and quitting smoking are effective primary prevention measures to reduce cardiovascular risk. </li>
            <li>Secondary prevention involves identifying and treating pre-clinical changes before they progress into full-blown diseases. This phase commonly uses screening procedures, enabling timely and cost-effective interventions. By detecting potential health issues early on, secondary prevention ensures more effective treatments and improved outcomes. </li>
            <li>Tertiary prevention concentrates on reversing, halting, or delaying the progression of existing conditions. It falls within the realm of clinical care and aims to minimize the impact of the illness on a patient's life. It also involves regular contact with healthcare providers across different specialties to manage various conditions effectively. </li>
        </ul>
            </p>
            </div>
            </div>
            <div className="d-none d-md-block col-12 col-md-5 section-img-2" style={{backgroundImage:`url(${Img2})`}}>
            <img className='img-fluid invisible' alt="Preventive Care Doctor in Huntsville" src={Img2} />    
            </div>
        </div>
        <div className="row" style={{backgroundColor: "#f4e9e4"}}>
            <div className="col-12 col-md-5 section-img-1" style={{backgroundImage:`url(${Img3})`}}>
            <img className='img-fluid invisible' alt="Preventive Care Clinic in Huntsville" src={Img3} />    
            </div>
            <div className="col-12 align-self-center col-md-7 py-5 ps-xl-5"> 
            <div className=" px-xl-5  px-3">
            <h2 className="font-35 font-pri-hsb"> Embracing the <span className="fw-800 text-pri-hsb"> Benefits of Preventive Care for a Healthy Life: </span> </h2>
            <hr className="hr w-25" />
            <p className="font-17 font-sec2-hsb fw-500">
            At My Family MD - Efficient preventive care clinic in Huntsville, we understand the importance of preventive care services in keeping you healthy and identifying potential problems early. Our primary care services prioritize preventive care as one of our main objectives. If you're uncertain about which preventive care measures you may need, don't hesitate to reach out to our dedicated team of healthcare professionals. Remember, prevention is often the best cure! Stay healthy and embrace the benefits of preventive care services to live a fulfilling life.
            </p>
            </div>
            </div>
        </div>
        </section>
        <div className="pt-4">
        </div>
        <MiddleBannerMap/>
        <OurInsurance/>
        <TakeActionToday/>
        <Footer/>
        </>
        
    )
};

export default PreventiveCare;