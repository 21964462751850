import React, {useEffect} from "react"
import { Link } from "react-router-dom"
import Header from "../components/layout/Header"
import Footer from "../components/layout/Footer";
import BelowBanner from "../components/layout/BelowBanner";
import OurServices from "../components/home/OurServices";
import DoctorBanner from "../components/layout/DoctorBanner";
import Dr from "../assets/images/home/Dr.webp"
import TakeActionToday from "../components/layout/TakeActionToday";
import HomeBanner from "../components/home/HomeBanner";
import Icon1 from "../assets/images/home/Icon1.png"
import Icon2 from "../assets/images/home/Icon2.png"
import Icon3 from "../assets/images/home/Icon3.png"
import Img1 from "../assets/images/home/Img1.png"
import Img2 from "../assets/images/home/Img2.png"
import ThreeHeadings from "../components/home/ThreeHeadings";
import { Helmet } from "react-helmet";
import {HomeSchema} from '../components/schemas/HomeSchema';
import SlickSlider from "../components/SlickSlider";
import MetaTags from 'react-meta-tags';





const Home=()=> {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
        <MetaTags>
            <title> My Family MD - Trusted Internal Medicine Clinic in Huntsville, AL </title> 
        <meta name="description" content="Experience exceptional primary & preventive care at My Family MD, the top internal medicine clinic in Huntsville. Schedule an appointment now!" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="My Family MD - Trusted Internal Medicine Clinic in Huntsville, AL" />
        <meta property="og:description" content="Experience exceptional primary & preventive care at My Family MD, the top internal medicine clinic in Huntsville. Schedule an appointment now!" />
        <meta property="og:url" content="https://md.eacademe.org/" />

        <meta property="og:site_name" content="myfamilymd" />
        <meta property="og:image" content={Img1} />

        <meta property='og:image:width' content='1200' />
        <meta property='og:image:height' content='630' />
        <meta property="og:image:secure_url" content="https://md.eacademe.org/og.png" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:description" content="Experience exceptional primary & preventive care at My Family MD, the top internal medicine clinic in Huntsville. Schedule an appointment now!" />
        <meta name="twitter:title" content="My Family MD - Trusted Internal Medicine Clinic in Huntsville, AL" />
        <meta name="twitter:image" content={Img1}/>

        <script className='structured-data-list' type="application/ld+json">{HomeSchema()}</script>

        </MetaTags>
        <Header/>
        <HomeBanner/>
        <section className="pt-4 pt-lg-0"/>
        <BelowBanner
        Title1="Welcome to"
        Title2="My Family MD"
        Para="At My Family MD – the finest internal medicine clinic in Huntsville, we are deeply committed to inspiring and empowering you to prioritize your health above all else. Our unwavering dedication is evident in our unwavering pursuit of delivering exceptional care tailored to your unique needs. With a steadfast focus on primary and preventative caress, we strive to equip you with the knowledge and tools necessary to take control of your well-being, ensuring that you thrive in every aspect of your life. By incorporating advanced technology into our practice, we stay at the forefront of medical innovation, enabling us to provide you with the most effective and efficient care available. We invite you to experience the transformative difference at My Family MD, where your health is our top priority, and your journey to optimal well-being is our shared mission. " 
        />
        <OurServices/>
        <DoctorBanner 
            SubTitle="Dr. Iqbal Saeed" 
            Title1="MEDICAL" 
            Title2="DIRECTOR"
            Para="Dr. Iqbal Saeed is a board certified internal medicine physician. He has been practicing medicine in Alabama since 1996. He completed his residency training from New York City. Dr. Saeed is a caring and compassionate physician who listens to the concerns of his patients and their needs. Come see if our practice may be the right fit for your journey to better health."
            Img={Dr}
            Button={<Link to="/providers">
            <button className="mt-4 lh-30 px-4 border-0 bg-sec text-white rounded-3 fw-600 font-pri-hsb">
                Meet Our Providers
            </button>
            </Link>}
            />
            <div className="">
            <div className="pt-5" >
        <ThreeHeadings
        Img={Icon1}
        Title1_1="Internal Medicine Services"
        Title1_2="for Adults"
        Para1="Our internal medicine physicians in Huntsville, Al specialize in providing comprehensive healthcare services for adults. Whether you need routine check-ups, preventive care, or management of chronic conditions, our internal medicine team is here to address your specific health needs. With their expertise and specialized knowledge, our physicians deliver personalized care to help you achieve and maintain optimal health. "
        />
        <ThreeHeadings
        Img={Icon2}
        Title1_1="Compassionate"
        Title1_2="Family Care"
        Para1="We understand the importance of having a trusted family care doctor in Huntsville, Al who can provide comprehensive healthcare services for your entire family. Our family care doctors are experienced in treating patients of all ages, from newborns to seniors. Whether it's well-child visits, immunizations, acute illness care, or ongoing management of chronic conditions, our family care team is dedicated to delivering compassionate and patient-centered care for every member of your family."
        />
        <ThreeHeadings
        Img={Icon3}
        Title1_1="Preventive Care for"
        Title1_2="Better Health"
        Para1="At My Family MD, we place a strong emphasis on preventive care. We believe preventing health issues before they arise is key to maintaining overall wellness. Our internal medicine and family care physicians work closely with you to develop personalized preventive care plans. Through regular check-ups, screenings, vaccinations, and lifestyle guidance, we strive to keep you and your family healthy and help you avoid potential health problems."
        />
        </div>
        <div className="pt-5" >
            
            <section className="container-fluid">
        <div className="row" style={{backgroundColor: "#f4e9e4"}}>
            <div className="col-12 col-md-6 section-img-1" style={{backgroundImage:`url(${Img1})`}}>
            <img className='img-fluid invisible' alt="comprehensive internal medicine in Huntsville" src={Img1} />    
            </div>
            <div className="col-12 align-self-center col-md-6 p-md-5 p-3"> 
            <div className="">
            <h2 className="font-35 font-pri-hsb">
            Comprehensive Internal Medicine and <span className="fw-800 text-pri-hsb"> Family Care in Huntsville, AL </span>
            </h2>
            <hr className="hr w-25" />
            <p className="font-17 font-sec2-hsb fw-500">
            We are proud to offer comprehensive internal medicine and family care services in Huntsville, AL. Our team of skilled physicians includes internal medicine specialists and family care doctors who are dedicated to providing exceptional healthcare for individuals and families in our community. With a patient-centered approach and a commitment to delivering personalized and compassionate care, we strive to be your trusted partners in health, supporting you and your family at every stage of life.
            </p>
            </div>
            </div>
        </div>
        </section>
        <section className="container-fluid">
        <div className="row" style={{backgroundColor: "#f6f6f6"}}>
        <div className="d-block d-md-none col-12 col-md-6 section-img-2" style={{backgroundImage:`url(${Img2})`}}>
            <img className='img-fluid invisible' alt="family care clinic in huntsville" src={Img2} />    
            </div>
            <div className="align-self-center col-12 col-md-6 p-md-5 p-3" > 
            <div className="">
            <h3 className="font-35 font-pri-hsb">
            Choose My Family MD for <span className="fw-800 text-pri-hsb"> Comprehensive Healthcare </span>
            </h3>
            <hr className="hr w-25" />
            <p className="font-17 font-sec2-hsb fw-500">
            Experience the comprehensive internal medicine and family care services offered at My Family MD. Our team of skilled physicians in Huntsville, AL, is dedicated to delivering exceptional care tailored to your specific needs. Whether you require internal medicine services for adults or compassionate family care for your entire family, we are here to support your health and well-being. Trust us to be your partners in maintaining good health – schedule an appointment with us today.
            </p>
            </div>
            </div>
            <div className="d-none d-md-block col-12 col-md-6 section-img-2" style={{backgroundImage:`url(${Img2})`}}>
            <img className='img-fluid invisible' alt="family care clinic in huntsville"src={Img2} />    
            </div>
            </div>
            </section>

        </div>
        </div>
        <div className="pt-5" >
            <SlickSlider/>
        {/* <TestimonialsSection/> */}
        {/* <TestimonialsSectionAPI/> */}
        </div>


        <section className="container-fluid bg-pri">
        <div className="ps-xl-5 px-3 row justify-content-center align-items-center gy-4">
            <div className="col-12 col-xl-6 text-center text-xl-start py-4 py-md-0 ps-xl-5">
        <h3 className="font-pri-hsb text-white font-30 fw-600 text-capitalize ">
        Conveniently Located in Huntsville, AL 
        </h3>
        <p className="font-sec2-hsb text-white font-17 pe-xl-5">
        Convenience and accessibility are important factors when choosing a healthcare provider. Our clinic is conveniently located in Huntsville, AL, making it easy for you and your family to access the care you need close to home. We strive to provide timely appointments and offer flexible scheduling options to accommodate your busy lifestyle. 
        </p>
        <a href="https://www.google.com/maps/place/Iqbal+Saeed+MD+LLC/@34.699686,-86.6030182,18z/data=!4m6!3m5!1s0x88626cea37639e9b:0x5c30f864a919d024!8m2!3d34.6994209!4d-86.6028594!16s%2Fg%2F1tfbjg5q" className="td-none text-pri-hsb " target="_blank">
        <button className=" mt-md-4 lh-30 px-4 border-0 bg-sec text-white rounded-3 fw-600 font-pri-hsb">
            View on Google Map
        </button>
        </a>
            </div>
            <div className="col-12 col-xl-6 pb-3">
                <iframe className="w-100" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1640.1089022091344!2d-86.6030182!3d34.699686!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88626cea37639e9b%3A0x5c30f864a919d024!2sIqbal%20Saeed%20MD%20LLC!5e0!3m2!1sen!2s!4v1684468280661!5m2!1sen!2s" width="600" height="380" style={{border:"0px"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"/>
            </div>
        </div>
    </section>


        <div className="pt-5" >
        <TakeActionToday/>
        </div>
        <Footer/>
        </>
        
    )
};

export default Home;