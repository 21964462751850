export const TelemedicineSchema = () => {

  let data = {
    "@context": "https://schema.org", 
    "@type": "Service", 
    "serviceType": "Telemedicine", 
    "provider": { 
      "@type": "Organization", 
      "name": "My Family MD", 
      "url": "https://myfamilymd.org/", 
      "telephone": "(256)-489-9741" 
    }, 
    "areaServed": { 
      "@type": "City", 
      "name": "Huntsville" 
    }, 
    "description": "Convenient and accessible telemedicine services for remote consultations with healthcare providers. Receive virtual medical advice, diagnosis, and treatment from the comfort of your own home.", 
    "url": "https://myfamilymd.org/telemedicine-service-huntsville", 
    "image": "https://myfamilymd.org/og.png"     
  } 

  return JSON.stringify(data);
};