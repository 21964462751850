import React from 'react';
import { StarFill, StarHalf } from 'react-bootstrap-icons';

const SlickSliderOne = (props) => {
  const stars = (rating) => {
    let stars = [];
    for (let i = 1; i < rating; i++) {
        stars.push(<StarFill key={i} className="text-warning" />)
    }
    if (rating % 1 !== 0) {
        stars.push(<StarHalf key={rating} className="text-warning" />)
    }else{
        stars.push(<StarFill key={rating} className="text-warning" />)
    }
    return stars;
}
  return (
    <>
    <div className='mx-2 p-2 shadow-review rounded-2 my-3' style={{backgroundColor:"#fafafa", border:"2px solid #fafafa"}}>
      <div className='d-flex align-items-center'>
        <div className='me-3'>
        <img src={props.ImgReviews} alt={props.Name} className='' />
        </div>
     <div className='' style={{color:"#154fc1"}}>
      <strong> {props.Name} </strong> <br/>
      {stars(props.Rating)}
     </div>
      </div>
      <div className='pt-2' style={{height:125, overflowY:'scroll'}}>
      {props.Para}
      </div>
    </div>
    </>
  );
}

export default SlickSliderOne;
