import React, {useEffect} from "react"
import Header from "../components/layout/Header"
// import OldBelowBanner from "../components/OldBelowBanner";
import MiddleBannerMap from "../components/layout/MiddleBannerMap";
import OurInsurance from "../components/layout/OurInsurance";
import TakeActionToday from "../components/layout/TakeActionToday";
import FormsImg from '../assets/images/forms/FormImg.png';
import PatientInformation from '../assets/uploads/PatientInformation.pdf';
import MedicalHistory from '../assets/uploads/MedicalHistory.pdf';
import ROS from '../assets/uploads/ROS.pdf';
import PatientConsentForm from '../assets/uploads/PatientConsentForm.pdf';
import PrivacyPractices from '../assets/uploads/PrivacyPractices.pdf';
import HIPAA from '../assets/uploads/HIPAA.pdf';
import Footer from "../components/layout/Footer";
import BelowBanner from "../components/layout/BelowBanner";
import { Helmet } from "react-helmet";



const Forms=()=> {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
        <Helmet>
        <title> Forms | My Family MD </title>

        <meta name="description" content="Experience exceptional primary & preventive care at My Family MD, the top internal medicine clinic in Huntsville" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Forms | My Family MD" />
        <meta property="og:description" content="Experience exceptional primary & preventive care at My Family MD, the top internal medicine clinic in Huntsville" />
        <meta property="og:url" content="https://myfamilymd.org/" />
        <meta property="og:site_name" content="myfamilymd" />
        <meta property="og:image" content="https://myfamilymd.org/og.png" />
        <meta property='og:image:width' content='1200' />
        <meta property='og:image:height' content='630' />
        <meta property="og:image:secure_url" content="https://myfamilymd.org/og.png" />
        <meta name="twitter:card" conent="summary_large_image" />
        <meta name="twitter:description" content="Experience exceptional primary & preventive care at My Family MD, the top internal medicine clinic in Huntsville" />
        <meta name="twitter:title" content="Forms | My Family MD" />
        <meta name="twitter:image" content="https://myfamilymd.org/og.png" />

        </Helmet>
        <Header/>
        <section>
            <div className="px-md-5 mx-md-5 py-4">
        <BelowBanner
        Title1="Our Forms For"
        Title2="Patients"
        Para="Please print and fill the forms which correspond with your visit type. If you need any assistance with the forms, feel free to contact us during operating hours." 
        /></div>
            
            <div className="container-fluid bg-white">
            <div className="row mb-4 justify-content-evenly align-items-center">

                <div className="col-12 col-xl-5 px-md-5 mx-md-5 font-pri-hsb pb-4">
                    <h3 className="font-pri-hsb fw-700 font-25">
                    New Patient to Establish Care
                </h3>
                <ul className="forms-links">
                <a target="_blank" href={PatientInformation}><li>Patient Information</li></a>
                <a target="_blank" href={MedicalHistory}><li>Medical History</li></a>
                <a target="_blank" href={ROS}><li>Review of Systems</li></a>
                <a target="_blank" href={PatientConsentForm}><li>Patient Consent Form</li></a>
                <a target="_blank" href={PrivacyPractices}><li>Privacy Practices</li></a>
                </ul>
                    <h3 className="font-pri-hsb fw-700 font-25">
                    Information for Patients
                </h3>
                <ul className=" forms-links body-subheading">
                <a target="_blank" href={HIPAA}><li>HIPAA Form</li></a>
                </ul>
                </div>

                <div className="col-12 col-xl-5">
                <div className="mb-4">
                    <img className="w-100" src={FormsImg} alt="Forms"/>
                </div>
                </div>
            </div>
        </div>     
        </section>
        <MiddleBannerMap/>
        <OurInsurance/>
        <TakeActionToday/>
        <Footer/>
        </>
        
    )
};

export default Forms;