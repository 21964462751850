export const HomeSchema = () => {

  let data = {
    "@context": "https://schema.org", 
    "@type": "Organization", 
    "name": "My Family MD", 
    "url": "https://myfamilymd.org/", 
    "logo": "https://myfamilymd.org/og.png", 
    "description": "Empowering individuals and families to prioritize their health and well-being. Offering comprehensive healthcare services, including primary care, telemedicine, preventive care, and consultative referrals.", 
    "contactPoint": { 
        "@type": "ContactPoint", 
        "telephone": "(256)-489-9741", 
        "contactType": "customer service" 
    }, 

  "sameAs": [ 
    " https://www.facebook.com/people/My-Family-MD/100063556061289/", 
    " https://www.instagram.com/myfamilymd_/" 
     ]  
  } 

  return JSON.stringify(data);
};