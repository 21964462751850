import React from "react"
import { Link } from "react-router-dom"



const ServicesPara = (props) =>{
    return (
    <>
    <section className="container-fluid mb-3">
    <div className="row px-md-5">
    <div className="col-12 px-md-5 px-3">
        <h2 className="font-35 font-pri-hsb fw-600">
        {props.Title1_1} <span className="text-pri-hsb fw-800"> {props.Title1_2} </span>
        </h2>
    <p className="font-17 font-sec2-hsb fw-500">
                {props.Para1_1} <br/><br/>
                {props.Para1_2}
            </p>
        </div>
        <div className="col-12 px-md-5 px-3">
        <h2 className="font-35 font-pri-hsb fw-600">
        {props.Title2_1} <span className="text-pri-hsb fw-800"> {props.Title2_2} </span>
        </h2>
    <p className="font-17 font-sec2-hsb fw-500">
                {props.Para2}
            </p>
        </div>
            </div>
    </section>
    </>
    )
}

export default ServicesPara;