import React from "react"
import {TelephoneFill,EnvelopeFill,GeoAltFill, Instagram, Facebook} from 'react-bootstrap-icons';
import Mobile from '../../assets/images/contactus/Mobile.png'
import Divider from "../../assets/images/home/Divider.png"




const BelowBanner = (props) =>{
    return (
    <>
    {/* <section className="container-hsb mt-md-0 my-3">
        <div className="text-center"> 
            <h3 className="text-center font-35 font-pri-hsb">
                Contact Us
            </h3>
            <h3 className="text-center font-35 font-pri-hsb">
              My  <span className="fw-800 text-pri-hsb"> Family MD </span>
            </h3>
        </div>
    </section> */}

<section className="container-fluid mb-md-5 mb-4">
        <div className="text-md-center"> 
            <h3 className="text-center font-35 font-pri-hsb">
                Contact <span className="fw-800 text-pri-hsb"> Us </span>
            </h3>
            <img className="w-25 d-none d-md-inline" src={Divider} alt="" />
            {/* <p className="font-17 px-md-5 mx-md-5 pt-3 font-sec2-hsb">
                {props.Para}
            </p> */}
        </div>
    </section>

    <section className='container-fluid'>
                <div className='py-md-0 py-3'>
                <div className="py-md-5">
                    <div className='row justify-content-center text-center'>
                        <div className=' col-xl-3 mb-3 border-pri-end'>
                            <div className='my-2'>
                                <TelephoneFill className='text-pri-hsb' size={50} />
                                <div className='font-pri-hsb text-sec-hsb font-25 mt-3 mb-md-3'>
                                    <div className='fw-700' >Phone</div>
                                </div>
                            </div>
                            <div className='my-2'>
                                    <div className=''><a className="td-none text-dark font-20" target="_blank" href="tel:+12564899741">(256)-489-9741</a></div>
                            </div>
                            
                        </div>
                        <div className=' col-xl-3 mb-3'>
                            <div className='my-2'>
                                <EnvelopeFill className='text-pri-hsb' size={50} />
                                <div className='font-pri-hsb text-sec-hsb font-25 mt-3 mb-md-3'>
                                    <div className='fw-700' >Email</div>
                                </div>
                            </div>
                            <div className='my-2'>
                                    <div className=''><a className="td-none text-dark font-20"  target="_blank" href="mailto:office@iqbalsaeed.com">office@iqbalsaeed.com</a></div>
                            </div>
                            
                        </div>

                        <div className=' col-xl-3 mb-3 border-pri-start'>
                            <div className='my-2'>
                                <GeoAltFill className='text-pri-hsb' size={50} />
                                <div className='font-pri-hsb text-sec-hsb font-25 mt-3 mb-md-3'>
                                    <div className='fw-700' >Address</div>
                                </div>
                            </div>
                            <div className='my-2 px-md-4'>
                                    <div className=''><a className="td-none text-dark font-20"  target="_blank" href="https://www.google.com/maps/place/Iqbal+Saeed+MD+LLC/@34.699686,-86.6030182,18z/data=!4m6!3m5!1s0x88626cea37639e9b:0x5c30f864a919d024!8m2!3d34.6994209!4d-86.6028594!16s%2Fg%2F1tfbjg5q">2227 Drake Ave. Suite 7A Huntsville, AL 35805</a></div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                </div>
            </section>

            <section className="container-fluid pt-md-4">
                <div className="row justify-content-center gy-5">             
                <div className="col-11 col-xl-5 border-pri-end pe-md-5">
                    <img className="w-100" src={Mobile} alt="" />
                </div>
                    <div className="col-12 col-xl-5 ps-xl-5 ms-xl-5">
                    <div className="w-100 bg-pri p-md-5 py-2 px-4">
                    <h2 className="font-pri-hsb text-white fw-bold px25-font mt-3">
            WORKING HOURS
        </h2>
		<table className="text-white table table-font-contact font-pri-hsb mt-3 w-100  ">
			<tbody>
				<tr>
					<td className="fw-bold" >Monday</td>
					<td>7:30AM - 4:00PM</td>
				</tr>
				<tr>
					<td className="fw-bold" >Tuesday</td>
					<td>7:30AM - 4:00PM</td>
				</tr>
				<tr>
					<td className="fw-bold" >Wednesday</td>
					<td>7:30AM - 11:30AM</td>
				</tr>
                <tr>
					<td className="fw-bold" >Thursday</td>
					<td>7:30AM - 4:00PM</td>
				</tr>
                <tr>
					<td className="fw-bold" >Friday</td>
					<td>7:30AM - 11:30AM</td>
				</tr>
                <tr>
					<td className="fw-bold" >Sat - Sun</td>
					<td>Closed</td>
				</tr>
                <tr>
					<td className="fw-bold" >Lunch Break</td>
					<td>12:00PM - 1:00PM</td>
				</tr>
			</tbody>
		</table>
        </div>
        <h3 className="mt-4 font-35 font-pri-hsb fw-700 text-pri-hsb ">
            Follow Us
            </h3>
            <div className="mt-1" >
            <span className="bg-light py-2 px-2" >
            <a className="td-none text-dark pe-4" style={{fontSize:"30px"}}  target="_blank" href="https://www.instagram.com/myfamilymd_/" >
        {/* <i className="bi bi-instagram pe-2 font-20" > </i>  */}
        <Instagram className='me-2' size={20} />
        <span className="font-20"> Instagram  </span> </a>
        <a className="td-none text-dark" style={{fontSize:"30px"}}  target="_blank" href="https://www.facebook.com/profile.php?id=100063556061289">
            {/* <i className="bi bi-facebook font-20"> </i>  */}
            <Facebook className='me-2' size={20}/>
            <span className="font-20"> Facebook  </span> </a>
            </span>
            </div>
        </div>
                </div>
            </section>

            <div className="container-fluid row justify-content-center pt-5 px-xl-5">
            <div className="col-12 col-xl-11">
                <iframe className="w-100" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1640.1089022091344!2d-86.6030182!3d34.699686!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88626cea37639e9b%3A0x5c30f864a919d024!2sIqbal%20Saeed%20MD%20LLC!5e0!3m2!1sen!2s!4v1684468280661!5m2!1sen!2s" width="600" height="500" style={{border:"0px"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"/>
            </div>
            </div>
    </>
    )
}

export default BelowBanner;